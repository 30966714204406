@charset "UTF-8";

.remote-control-device {
  user-select: none;
  //border: 3px solid transparent;
  //border-radius: 4px;
  &__screen {
    user-select: none;
  }
  &--active {
    &:hover {
      //border-color: #003460;
    }
  }
}
