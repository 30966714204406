@import 'variables';

.app {
  position: relative;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  background-color: $primary-background-color;
  overflow: hidden;
  flex-direction: column;

  &__content-outer-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    &--with-banner {
      height: calc(100% - #{$banner-height});
    }
    &--with-toolbar {
      bottom: $toolbar-height;
      height: calc(100% - #{$toolbar-height});
    }
  }

  &__content-inner-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% - #{$sidebar-width});
    &--with-full-width {
      width: 100%;
    }
  }

  &__content {
    position: absolute;
    z-index: 1;
    top: $header-height;
    left: $sidebar-width;
    right: 0;
    bottom: 0;
    transition: bottom 0.2s ease;
    &--with-toolbar {
      bottom: $toolbar-height;
    }
    &--with-banner-and-toolbar {
      bottom: $toolbar-height;
      height: calc(100% - #{$toolbar-height} - #{$toolbar-height});
    }
    // @media (max-width: 799px) {
    //  left: 0;
    //}
  }
}

.page-content {
  height: 100%;
  width: 100%;
  padding: 28px 32px 36px;
  display: flex;
  flex-wrap: nowrap;
}

.app__header {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  //position: fixed;
  top: 0;
  left: $sidebar-width;
  z-index: 2;
  right: 0;
}

.throbber {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__background {
    width: 100%;
    height: 100%;
    min-width: 160px;
    min-height: 160px;
    max-width: 320px;
    max-height: 320px;
    display: flex;
    background-image: url('../images/springdel-rocket-loading-animation.svg');
    &--incube {
      background-image: url('../images/incube-rocket-loading-animation.svg');
    }
    &--janam {
      background-image: url('../images/janam-rocket-loading-animation.svg');
    }
    background-size: 80% 80%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.table-root {
  height: 100%;
}

.table-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.table-header {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 0.6px;
  color: #000000;
}

.table-wrapper {
  height: 100%;
}

.breadcrumbs-section {
  width: 100%;
  height: 32px;
  border-radius: 0;
  padding: 12px 32px 0;
  background-color: white;
  margin-bottom: 0;
  display: inline-flex;
}
.breadcrumbs__item {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none !important;
  border-bottom: none;
  padding-bottom: 2px;
  margin-right: 32px;
  color: #000;
  white-space: nowrap;
  &--active,
  &:hover {
    border-bottom: 2px solid $primary-color;
    text-decoration: none;
    color: $primary-color;
  }
}

.breadcrumbs__item--incube {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none !important;
  border-bottom: none;
  padding-bottom: 2px;
  margin-right: 32px;
  color: #000;
  white-space: nowrap;
  &--active,
  &:hover {
    border-bottom: 2px solid $incube-primary-color;
    text-decoration: none;
    color: $incube-primary-color;
  }
}
.breadcrumbs__item--janam {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: normal;
  text-decoration: none !important;
  border-bottom: none;
  padding-bottom: 2px;
  margin-right: 32px;
  color: #000;
  white-space: nowrap;
  &--active,
  &:hover {
    border-bottom: 2px solid $janam-darkest-color;
    text-decoration: none;
    color: $janam-darkest-color;
  }
}
