.sso-settings {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 650px;
  height: 100%;
  padding: 38px 60px;
  white-space: normal;
  overflow-y: auto;
}
