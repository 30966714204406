.springdel-checkbox {
  display: flex;
  align-items: center;
  height: 25px;
  width: 100%;

  label {
    margin-bottom: 0;
    padding-left: 10px;
  }
  input[type='checkbox'] {
    margin-top: 0;
  }
}
