.app-list-wrapper-container {
  width: 100%;
  background-color: #fff;
}
.app-list-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}
.app-list {
  height: 100%;
  width: 100%;
  display: flex;
  &-selected {
    display: flex;
    width: 50%;
    flex-direction: column;
    margin: 20px 20px 20px 24px;

    &-header {
      margin-bottom: 15px;
      min-height: 20px;
      display: inline-flex;
      align-items: center;
      &-title-section {
        display: flex;
        flex-direction: column;
        &-header-title {
          font-size: 18px;
          display: inline-flex;
          align-items: center;
          &__switch-title {
            margin-right: 15px;
          }
        }
      }
      &-actions-section {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        cursor: pointer;
        font-weight: 400;
        margin-left: 20px;
        &__action-button {
          width: 24px;
          opacity: 0&-8;
          height: 18px;
          border-radius: 50%;
          cursor: pointer;
          :hover {
            opacity: 1;
            background-color: #f2f2f2;
          }
        }
      }
    }

    &-content {
      width: 100%;
      min-width: 250px;
      border: 1px solid lightgray;
      margin-top: -1px;
      &-list {
        &-header {
          width: 100%;
          min-width: 250px;
          display: inline-flex;
          border-bottom: 1px solid lightgray;
          padding: 10px;
          justify-content: space-around;
          text-transform: uppercase;
        }
        &-item {
          display: inline-flex !important;
          justify-content: space-around;
          width: 100%;
          &-left-cell {
            width: 50%;
            margin-right: 5px;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
          }
          &-right-cell {
            width: 50%;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
          }
        }
      }
      &-empty-list {
        display: flex;
        height: 100%;
        min-height: 250px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-text {
          color: lightgray;
          margin-top: 30px;
        }
      }
      &-upload-icon {
        color: lightgray;
        font-size: 46px;
        margin-top: 30px;
      }
    }
  }
  &-available {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 250px;
    border-left: 1px solid lightgray;
    &-header {
      margin: 24px 20px 29px;
      &-title-section {
        display: flex;
        flex-direction: column;
        margin-right: 40px;
        &__title {
          font-size: 18px;
          display: inline-flex;
          align-items: center;
        }
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 250px;
      &-list {
        &-header {
          width: 100%;
          min-width: 250px;
          display: inline-flex;
          border-bottom: 1px solid lightgray;
          border-top: 1px solid lightgray;
          padding: 10px;
          justify-content: space-around;
          text-transform: uppercase;
        }
        &-item {
          display: inline-flex;
          justify-content: space-around;
          width: 100%;
          &-left-cell {
            width: 80%;
            margin-right: 5px;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
          }
          &-right-cell {
            width: 20%;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
}
