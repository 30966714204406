$color-blue: #1385e5;
$color-gray: #dfe0e4;
$border-gray: #c7c7c7;
$color-white: #ffffff;
$color-black: #000;
$slider-handle-size: 20px;
$slider-size: 4px;
$slider-fix: 20px;

$color-neutral-blue: #008aff;
$color-success: #16c98d;
$color-warning: #ffdf5a;
$color-danger: #ff5353;
$color-default: #000;

$dark-blue: #003562;
$incube-dark-blue: #1a1e35;
$janam-dark-color: #ff7600;
$janam-darkest-color: #555;
$navi: #003460;
$dark-navi: #002340;
$lighter-gray: #c6c6c6;
$light-gray: #f2f2f2;
$inactive-gray: #a7a8aa;
$medium-gray: #727272;

$primary-color: $dark-blue;
$incube-primary-color: #1a1e35;
$janam-primary-color: $janam-dark-color;
$primary-gradient: linear-gradient(180deg, $dark-blue, $navi, $dark-navi);

$incube-primary-gradient: linear-gradient(
  180deg,
  $incube-dark-blue,
  $dark-navi,
  #222
);
$janam-primary-gradient: linear-gradient(
  359deg,
  #f7b42c 0%,
  $janam-dark-color,
  #fc575e 74%
);
$primary-background-color: $light-gray;

$secondary-background-color: $color-white;

$card-shadow: 3px 1px 6px 0px rgba(0, 0, 0, 0.1);

$sidebar-width: 72px;
$sidebar-margin: 72px + 2px;

$header-height: 88px;
$toolbar-height: 75px;

$header-min-height: 80px;
$mini-header-min-height: 70px;

$banner-height: 5em;
