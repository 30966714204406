.springdel-text-area {
  width: 100%;
  &__label {
    display: flex;
    flex-direction: column;
    font-weight: normal;
  }
  &__hint {
    font-weight: lighter;
  }
  &__input {
    overflow: hidden;
    width: 100%;
    height: 300px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    &:focus {
      border-color: #66afe9;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }
  }
}
