.read-only-boolean-list-item {
  display: flex;
  height: 35px;
  justify-content: space-between;
  max-width: 600px;
  width: 70%;
  align-items: center;
  margin-bottom: 10px;

  &__title-area {
    display: flex;
  }

  &__icon {
    width: 18px;
    height: 18px;
  }
}
