.wallpaper-settings {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__editing-view {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  &__selections-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 20px;
    margin: 0 20px;
    width: 40%;
  }
  &__source-list {
    display: block;
    width: 30%;
    max-width: 300px;
  }
  &__settings-section {
    width: 100%;
  }
}
