@charset "UTF-8";

.device-info {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  &__content {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 15px;
    max-height: 228px;
    height: 228px;
  }
}

.device-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;
  max-height: 9rem;
  justify-content: space-around;
  &--action-dropdown-text {
    text-transform: uppercase;
  }
  &-caption-text {
    margin-left: 40px;
    align-items: center;
    height: 30px;
    font-size: 30px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.6;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    display: inline-flex;
    flex-wrap: nowrap;
    &__prefix-icon {
      width: 14px;
      height: 22px;
      margin-right: 12px;
    }
    &__title {
      font-size: 18px;
    }
    &__ending-icon {
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin-left: 12px;
    }
  }
}
