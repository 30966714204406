.alert-row {
  &__status {
    &--active {
      color: #065829;
    }
    &--inactive {
      color: #ce1f12;
    }
  }

  &__alert-type {
    display: flex;
    align-items: center;
  }

  &__alert-type-icon {
    height: 20px;
    width: 25px;
    margin-right: 3px;
  }

  &__linked-fleets {
    background-color: #003562;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
  }

  &__total-alerts-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__total-alerts {
    background-color: #ff6f4a;
    border-radius: 50%;
    padding: 3px 6px;
    color: white;
  }
}

.alert-creation {
  &__communication-channel-repeat-container {
    display: flex;
    height: 70px;
    align-items: center;
  }
  &__communication-channel-repeat-input-field {
    margin: 15px 10px 0 10px;
  }
}
