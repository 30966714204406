.data-table {
  display: block;
  padding: 0 15px;
  height: 100%;
  max-width: 100%;
  overflow-x: scroll;
  &__row {
    border-bottom: 0;
  }
  &__head,
  &__data {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;
  }
  &__truncatable-data {
    white-space: pre-wrap;
    text-overflow: ellipsis;

    //display: -webkit-box;
    //-webkit-line-clamp: 3;
    //-webkit-box-orient: vertical;
    overflow: hidden;
  }
  &__loader {
    height: 30px;
  }
  &__resizer {
    right: 0;
    background: lightgray;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    /* prevents from scrolling while dragging on touch devices */
    touch-action: none;

    &--is-resizing {
      background: red;
    }
  }
  &__message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
