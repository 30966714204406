.custom-radio-button-with-image {
  margin: 10px;
}

.custom-radio-button-with-image input[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-radio-button-with-image input[type='radio'] + .radio-img {
  cursor: pointer;
}

.custom-radio-button-with-image input[type='radio']:checked + .radio-img {
  outline: 2px solid #003562;
}
