.radio-button-group {
  margin-bottom: 15px;
  &__radio {
    margin: 0;
  }
  &__options-container {
    display: flex;
    flex-direction: row;
    &--column-view {
      flex-direction: column;
    }
  }
  &__group-label {
    font-weight: normal;
  }
  &__option {
    display: flex;
    align-items: center;
    min-height: 4rem;
    margin: 0 10px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  &__option-label {
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: normal;
  }
}
