@import 'variables';

.springdel-popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100000000;

  &__popup-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    max-width: 412px;
    width: 92%;
    margin: auto;
    outline: none;
    min-height: auto;
    border-radius: 6px;
    padding: 20px;
    box-shadow: 0 0 7px 0 rgba(150, 150, 150, 0.5);
  }

  &__options-list {
    max-height: 350px;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__popup-title {
    font-size: 21px;
    font-weight: bolder;
    margin-bottom: 25px;
  }

  &__message-container {
    overflow-y: scroll;
    max-height: 350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__popup-footer {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 25px;
  }

  &__text {
    font-size: 16px;
    margin: 5px 0;
    &--default {
      color: $color-default;
    }
    &--danger {
      color: $color-danger;
    }

    &--warning {
      color: $color-warning;
    }

    &--info {
      color: $color-neutral-blue;
    }

    &--success {
      color: $color-success;
    }
  }

  &__secondary-action {
    align-self: flex-start;
  }
}
