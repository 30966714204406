.drag-n-drop-list {
  border: 1px solid lightgray;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 1px 6px 0px;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  min-height: 300px;
  &--short {
    height: 10%;
    max-height: 10%;
    min-height: 65px;
  }
  &--medium {
    height: 20%;
    max-height: 25%;
    min-height: 140px;
  }
  &--no-borders {
    border: none;
    box-shadow: none;
  }
}

.drag-n-drop-destination-list-header {
  width: 100%;
  display: inline-flex;
  border-bottom: 1px solid lightgray;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  &--incube {
    background-color: $incube-primary-color;
  }
  &--janam {
    background-color: $janam-primary-color;
  }
  height: 32px;
  color: white;
  &__margin {
    width: 145px;
  }
  &__title {
    width: 100%;
    line-height: 1;
  }
  &-button {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 400;
    margin-left: 20px;
    color: white;
    &__icon {
      width: 24px;
      opacity: 0.8;
      height: 18px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.drag-n-drop-source-list-header {
  margin: 24px 20px 0 29px;
}

.drag-n-drop-list-content {
  &-item {
    display: inline-flex;
    justify-content: space-around;
    &__left-cell {
      width: 80%;
      margin-right: 5px;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
    }
    &__right-cell {
      width: 20%;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
    }
    &__cell {
      text-overflow: ellipsis;
      overflow-wrap: anywhere;
      width: 100%;
      margin: 0 5px;
    }
  }
  &__blank {
    width: 100%;
    height: 100%;
    min-height: 32px;
  }
}
