.preferences-settings {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 650px;
  height: 100%;
  padding: 38px 60px;
  white-space: normal;
  overflow-y: auto;
}

.preferences-settings {
  width: 75%;
  &__buttons-container {
    margin: 15px 0;
    display: flex;
    gap: 5px;
  }
}
