.attributive-table {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &__inner-container {
    width: 100%;
    margin-bottom: 5px;
    background: white;
  }

  &__header-row {
    padding: 10px;
    min-height: 34px;
    height: 34px;
    border-bottom: 1px solid #f2f2f2;
  }

  &__header-checkbox-container {
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: #fff;
    //padding: 0;
  }

  &__header-checkbox-inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  &__header-cell-container {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 10px;
    background: #fff;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.6px;
    color: #000000;
  }

  &__header-cell-inner-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: default;
    &--sortable {
      cursor: pointer;
    }
  }

  &__infinite-scroll-loader-container {
    height: 15px;
    width: 100%;
  }

  &__empty-message-container {
    display: flex;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    background: white;
  }
  &__empty-message-text {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
