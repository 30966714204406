@import 'variables';

.banner {
  &__container {
    width: 100%;
    background-color: red;
    color: white;
    height: $banner-height;
  }

  &__inner-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
  }

  &__close-icon {
    flex-basis: 50px;
  }

  &__text {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: bolder;
  }
}
