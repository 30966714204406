@import 'variables';

.fleets-tree-card {
  background-color: $secondary-background-color;
  box-shadow: $card-shadow;
  display: flex;
  flex-direction: column;
  max-width: 30%;
  min-width: 200px;
}

.fleets-tree__content {
  .rc-tree li span.rc-tree-checkbox {
    display: none;
  }
}

.fleet-tree {
  margin: 0;
  padding: 0 !important;
  width: 100%;
  height: auto !important;

  li.fleet-node.rc-tree-treenode-switcher-open {
    background-color: #f6f6f6;
  }
  li.fleet-node--empty.rc-tree-treenode-switcher-open {
    background-color: #fff;
  }
  li.fleet-node--root.rc-tree-treenode-switcher-open {
    background-color: #fff;
  }

  li.drag-over > .draggable {
    background-color: $dark-blue !important;
    color: white;
    border: 1px #316ac5 solid;
    opacity: 0.8;
  }

  li.drag-over-gap-bottom > .draggable {
    border-bottom: 2px solid $dark-blue !important;
  }

  li {
    li.fleet-node.rc-tree-treenode-switcher-close,
    li.fleet-node.rc-tree-treenode-switcher-open {
      .rc-tree-checkbox {
        display: none;
      }
    }

    li.fleet-node.rc-tree-treenode-switcher-close.rc-tree-treenode-selected,
    li.fleet-node.rc-tree-treenode-switcher-close.rc-tree-treenode-checkbox-checked,
    li.fleet-node.rc-tree-treenode-switcher-open.rc-tree-treenode-checkbox-checked {
      background: #f5f5f5 !important;

      &:hover {
        background: #f5f5f5 !important;
      }
      .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-normal,
      .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-close,
      .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open {
        background: #f5f5f5;

        span.rc-tree-title {
          border-left: 2px solid $janam-darkest-color;
          font-weight: bold;
        }
      }
    }

    ul {
      padding: 0 !important;
      background-image: none !important;
    }

    .rc-tree-node-content-wrapper {
      height: 32px !important;
      display: inline-flex !important;
      align-items: center;
      padding-left: 7px;
      margin-left: -12px;
      width: 100%;
      border: none;
      border-left: 2px solid transparent;

      .rc-tree-icon__customize {
        display: none;
      }

      .rc-tree-title {
        padding-left: 38px;
        height: 100%;
        align-items: center;
        display: flex;
      }
    }

    .rc-tree-node-content-wrapper.rc-tree-node-selected {
      background: #f5f5f5;

      span.rc-tree-title {
        border-left: 2px solid $primary-color;
        font-weight: bold;
      }
    }
  }

  &--incube {
    li.drag-over > .draggable {
      background-color: $incube-dark-blue !important;
      color: white;
      border: 1px #316ac5 solid;
      opacity: 0.8;
    }

    li.drag-over-gap-bottom > .draggable {
      border-bottom: 2px solid $incube-dark-blue !important;
    }
    li {
      .rc-tree-node-content-wrapper.rc-tree-node-selected {
        background: #f5f5f5;

        span.rc-tree-title {
          border-left: 2px solid $incube-primary-color;
          font-weight: bold;
        }
      }
    }
  }
  &--janam {
    li.drag-over > .draggable {
      background-color: $janam-darkest-color !important;
      color: white;
      border: 1px #316ac5 solid;
      opacity: 0.8;
    }

    li.drag-over-gap-bottom > .draggable {
      border-bottom: 2px solid $janam-darkest-color !important;
    }
    li {
      .rc-tree-node-content-wrapper.rc-tree-node-selected {
        background: #f5f5f5;

        span.rc-tree-title {
          border-left: 2px solid $janam-darkest-color;
          font-weight: bold;
        }
      }
    }
  }

  span.rc-tree-switcher {
    display: flex !important;
    left: 26px !important;
    position: absolute;
    z-index: 2;
    align-items: center;
    height: 32px !important;
    background-image: none !important;
  }
}

.fleet-node {
  padding-left: 10px !important;
  position: relative;

  &--root {
    margin-left: -10px !important;
  }
  &:empty {
  }
}

.profile-assignment {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
