.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #003562;
}

.DateInput_input__small::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #003562;
  opacity: 1; /* Firefox */
}

.DateInput_input__small:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #003562;
}

.DateInput_input__small::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #003562;
}

.DateRangePicker_picker {
  z-index: 3;
  background-color: #fff;
  position: absolute;
}
