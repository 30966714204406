@charset "UTF-8";

@import 'variables';
@import 'app';
@import 'app/src/features/login/login';
@import 'sidebar';
@import 'dialog';
@import 'createEnrollment';
@import 'enrollmentDeviceNaming';

@import 'home';
@import 'headertitle';
@import 'devices';
@import 'createprofile';

@import 'profileDetail';
@import 'profile';

@import 'toolbar';
@import 'fleets';
@import 'fleetsTree';
@import 'search';
@import 'filter';

@import 'applications';
@import 'logs';
@import 'overview';
@import 'profilesAssigned';

@import 'appManagement';
@import 'featureControl';
@import 'certificates';
@import 'applicationBundle';
@import 'authentication';
@import 'appList';

@import 'security';
@import 'kiosk';
@import 'password';
@import 'wifi';
@import 'fileSync';
@import 'kioskBuilder';

@import 'users';
@import 'createUserGroup';
@import 'dashboard';
@import '../../features/account/account';
@import 'profileBar';
@import 'upload';
@import 'creationWizard';
@import 'alerts';
@import 'entityCreationWizardStepsNavigation';
@import 'customRadioButtonWithImage';
@import 'dragNDropList';
@import 'wallpaperSettings';
@import 'dropDownMenu';
@import 'attributiveTable';
@import 'dataTable';
@import 'banner';
@import 'dateRangePickerStylesOverRides';

@import '../../features/preferences/preferences';

@import '~ol-geocoder/dist/ol-geocoder.css';
@import '~rc-tree/assets/index.css';
@import '~react-dates/lib/css/_datepicker.css';
@import '~react-datetime/css/react-datetime.css';
@import '~ol/ol.css';
@import '~rc-slider/assets/index.css';

.login-body {
  background-color: #1d3c6d;
}

.login-button {
  color: #fcfdff !important;
  background-color: #1d3c6d !important;
  opacity: unset !important;
  width: 100px;
  height: 30px;
  border-radius: 2px;
}

.login-content-holder {
  display: inline-grid;
  margin-bottom: 1rem;
}

#forgotpwdlink {
  font-size: 1.3rem;
  color: #1d3c6d;
  font-weight: 100;
  text-decoration: underline;
}

#react-confirm-alert {
  position: fixed;
  z-index: 9;
  top: 15%;
  left: 40%;
}

.forgot-button {
  width: 31rem;
  margin-top: 1rem;
}

.detailObjs {
  display: flow-root;
  border-bottom: 1px solid #c6c6c6;
  padding: 10px;
}

.detailObjLabel {
  float: left;
  font-weight: 600;
}

.detailObjValue {
  float: right;
}

.homecontent {
  padding-left: 16px;
  height: 100%;
}

.homeContentUpper {
  padding-left: 16px;
  height: 100%;
}

.online-status {
  font-size: 12px;
  width: 16px;
  height: 15px;
  background-color: #00e6b8;
  border-radius: 5px;
  margin-top: 10px;
}

.online-status::after {
  content: 'Online';
  margin-left: 15px;
}

.line-chart-filter {
  width: 20rem;
  margin-left: -1rem;
}

.breadcrumb > .active span {
  clear: both;
  color: $primary-color !important;
  padding-bottom: 2px;
  border-bottom: 2px solid $primary-color;
  border-radius: 0px;
}

.breadcrumb--incube > .active span {
  clear: both;
  color: $incube-primary-color !important;
  padding-bottom: 2px;
  border-bottom: 2px solid $incube-primary-color;
  border-radius: 0px;
}

.breadcrumb > li + li:before {
  padding: 0 5px !important;
  color: #ccc !important;
  content: '\00a0' !important;
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  line-height: 18px !important;
  font-size: 12px !important;
}

.notification-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification,
.notification-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.notification {
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: 700;
  margin: 0 0 5px;
}

.notification:focus,
.notification:hover {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.notification-enter.notification-enter-active,
.notification-leave {
  visibility: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: Notification;
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: '';
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: '';
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: '';
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: '';
}

.Devices {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/**
* @deprecated class TODO: remove asap
**/
.devicescontent {
  padding-left: 16px;
  height: 100%;
  display: flex;
}

.enrollment-form {
  margin-top: 20%;
  text-align: center;
}

.create-enrollment-alert {
  padding: 40px;
  background: #fff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #000000;
  height: 200px;
}

.button {
  opacity: 1;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  background-image: none;
  border: 1px solid transparent;
  height: 32px;
  width: auto;
  outline: none;

  &--primary {
    background-color: $navi;
    color: #fff;
    border-radius: 2px;
    transition: background-color 0.5s ease-out;
    &:focus {
      color: #fff;
    }
    &--incube {
      background-color: $incube-primary-color;
    }
    &--active,
    &:hover {
      background: #0064b9;
      color: #fff;
    }
  }
  &--incube--primary {
    background-color: $incube-primary-color;
    color: #fff;
    border-radius: 2px;
    transition: background-color 0.5s ease-out;
    &:focus {
      color: #fff;
    }
    &--active,
    &:hover {
      background: #0064b9;
      color: #fff;
    }
  }
  &--janam--primary {
    background-color: $janam-primary-color;
    color: #fff;
    border-radius: 2px;
    transition: background-color 0.5s ease-out;
    &:focus {
      color: #fff;
    }
    &--active,
    &:hover {
      background: #fc575e;
      color: #fff;
    }
  }
  &:disabled {
    cursor: initial;
    opacity: 0.5;
  }
  &--secondary {
    letter-spacing: 0.6px;
    cursor: pointer;
    background-color: #fff;
    color: $primary-color;
    border: 1px solid $primary-color;
    &--active,
    &:hover {
      color: $primary-color;
      background-color: #f2f2f2;
    }
  }
  &--janam--secondary {
    letter-spacing: 0.6px;
    cursor: pointer;
    background-color: #fff;
    color: $janam-primary-color;
    border: 1px solid $janam-primary-color;
    &--active,
    &:hover {
      color: $janam-primary-color;
      background-color: #f2f2f2;
    }
  }
  &--incube--secondary {
    letter-spacing: 0.6px;
    cursor: pointer;
    background-color: #fff;
    color: $incube-primary-color;
    border: 1px solid $incube-primary-color;
    &--active,
    &:hover {
      color: $incube-primary-color;
      background-color: #f2f2f2;
    }
  }
  &--transparent {
    letter-spacing: 0.6px;
    cursor: pointer;
    background-color: #fff;
    color: $primary-color;
    &--active,
    &:hover {
      color: $primary-color;
      background-color: #fff;
    }
  }
}

.createEnDetails {
  height: 50rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.createEnBtnActions {
  margin-top: 10px;
  float: right;
  margin-right: 10px;
  text-align: right;
}

.device_naming_holder {
  display: grid;
}

.switch {
  border: 1px solid #ccc;
  width: 50px;
  min-width: 50px;
  height: 21px;
  border-radius: 13px;
  cursor: pointer;
  display: inline-block;
}

.switch.on .switch-toggle {
  left: 23px;
}

.switch-toggle {
  border: 1px solid #999;
  box-shadow: 1px 1px 1px #ccc;
  width: 25px;
  height: 19px;
  left: 0;
  border-radius: 12px;
  background: #fff;
  position: relative;
  transition: left 0.2s ease-in-out;
}

.detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  &__title {
    margin-bottom: 5px;
  }
  &__value {
    font-weight: bold;
    min-height: 18px;
  }
}

.section-description {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  &__title {
    font-family: Open Sans, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    font-size: 21px;
    line-height: 0.86;
    text-align: left;
    color: #000000;
  }
  &__title--small {
    font-size: 18px;
  }
  &__hint {
    font-family: Open Sans, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: 1.5;
    font-size: 12px;
    text-align: left;
    color: #000000;
    margin-top: 5px;
  }
}
.switch-field {
  margin-bottom: 15px;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  &--block-display {
    display: block;
  }
  .switch.on {
    background: $primary-color;
  }
  &--janam {
    .switch.on {
      background: $janam-primary-color;
    }
  }
  &--incube {
    .switch.on {
      background: $incube-primary-color;
    }
  }
  &__title {
    //white-space: nowrap;
    min-width: 150px;
    display: flex;
  }
}
.select-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  &--no-bottom-margin {
    margin-bottom: 0;
  }
  label {
    font-weight: normal;
  } // TODO : delete when we get rid of bootstrap lib
  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  &__hint {
    font-weight: lighter;
  }
}
.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  label {
    font-weight: normal;
  } // TODO : delete when we get rid of bootstrap lib
  &__title {
    margin-bottom: 5px;
  }
}

.date-field {
  &__title {
    margin-bottom: 5px;
  }
  &__input-container {
    display: flex;
    align-items: center;
  }
  &__input {
    margin-right: 5px;
  }
}

.text-button {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 400;
  margin-left: 80px;
  &--no-margin {
    margin: 0;
  }

  &__icon {
    width: 24px;
    opacity: 0.8;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.action-button {
  width: 24px;
  opacity: 0.8;
  height: 18px;
  // TODO: get dir of margin left, use diffrent positioning
  margin-left: 80px;
  border-radius: 50%;
  cursor: pointer;
}
.field-form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
}

.left-hand-pane-card {
  &__header {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    flex-wrap: nowrap;
    min-height: 70px;
    border-bottom: 1px solid #f4f4f4;
    padding-left: 23px;
  }
  &__content {
    display: flex;
    overflow-y: auto;
    height: 100%;
    position: relative;
    width: 100%;
  }
  &__footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-top: 1px solid #f2f2f2;
    justify-content: center;
    min-height: 56px;
    min-width: 70px;
  }
}

.card-wrapper {
  display: flex;
  width: 100%;
}

.card {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 200px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  backdrop-filter: blur(30px);
  flex-direction: column;
  &--compact {
    max-width: 20%;
    min-width: 200px;
    overflow-y: scroll;
  }
  &--horizontal {
    flex-direction: row;
  }
  &--right-hand {
    width: calc(80% - 32px);
  }
  &--left-hand {
    box-shadow: 3px 1px 6px 0 rgba(0, 0, 0, 0.1);
    max-width: 20%;
    width: 20%;
    min-width: 200px;
    margin-right: 32px;
  }
  &--hoverable {
    transition: all 0.2s ease;
    border: 1px solid #dce2e6;
    &:hover,
    &:focus {
      box-shadow: 4px 4px 4px 0 rgba(0, 52, 96, 0.16);
      border-color: $navi;
      border-radius: 2px;
      cursor: pointer;
    }
  }
  &--with-left-margin {
    margin-left: 10px;
  }
  &--with-padding {
    padding: 38px;
  }
}
.card-header {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 70px;
  border-bottom: 1px solid #f4f4f4;
  padding-left: 20px;
  padding-right: 20px;
  &__title {
    font-size: 18px;
  }
  &-actions {
    display: flex;
    align-items: center;
    &-section {
      position: relative;
      margin-left: 32px;
    }
  }
  &--custom-action {
    margin: 0 10px;
  }
}
.card-content {
  display: flex;
  width: 100%;
  height: calc(100% - 70px);
  max-height: calc(100% - 70px);
}
.btn-secondary {
  &:hover {
    color: white;
  }
}
.modal {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  display: block !important; // TODO: Get rid of bootstrapping modules and remove important flag then
  &__fade {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.2s ease;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &-window-container {
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
.modal-window {
  min-height: 200px;
  min-width: 300px;
  width: 640px;
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  max-height: 90%;
  flex-direction: column;
  padding: 0 20px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;
    &__left-action {
      cursor: pointer;
      border-bottom: 1px solid #fff;
      display: flex;
      flex-direction: column;
      &:hover {
        border-color: #fff;
      }
    }
    &__title {
      font-family: 'Open Sans';
      font-size: 21px;
      color: $primary-color;
      padding: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-content-wrapper {
    overflow: hidden;
    display: flex;
    height: 100%;
    width: 100%;
  }
  &__content {
    display: flex;
    width: 100%;
  }
}
.dialog-content {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  &-section {
    border-top: 1px solid lightgray;
    width: 100%;
    padding-left: 0;
    padding-top: 38px;
    &:first-child {
      border-top: 0;
      padding-top: 0;
      padding-bottom: 15px;
    }
  }
}

.dialog-content-container {
  display: flex;
  flex-direction: column;
  padding: 38px 10px 15px 60px;
  width: 100%;
}

.icon {
  height: 100%;
  &--fixed-square-small {
    width: 16px;
    height: 16px;
  }
  &--fixed-square {
    width: 18px;
    height: 18px;
  }
  &--fixed-square-20px {
    width: 20px;
    height: 20px;
  }
  &--fixed-square-2x {
    width: 36px;
    height: 36px;
  }
  &--original {
    color: $primary-color;
  }
  &--incube {
    color: $incube-primary-color;
  }
  &--janam {
    color: $janam-primary-color;
  }
  &--white {
    color: #fff;
  }
  &--black {
    color: #000;
  }
  &--success {
    color: #16c98d;
  }
  &--warning {
    color: rgb(255, 223, 90);
  }
  &--danger {
    color: #ff5353;
  }
  &--disabled {
    color: gray;
  }
  &--fleet {
    width: 13px;
  }
}

.table-stub {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  &__title {
    color: slategray;
    margin: 30px;
  }
}

.single-card {
  &-header {
    width: 100%;
    height: 96px;
    min-height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 32px;
    border-bottom: 2px solid #f2f2f2;
  }
  &-content {
    width: 100%;
    height: calc(100% - 96px);
  }
  &--full-height {
    height: 100%;
  }
  &__right-group {
    display: flex;
  }
}

.submission-input {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-right: 15px;
  align-items: center;
  &__input {
    padding-right: 60px;
    min-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__actions {
    margin-left: -60px;
    width: 60px;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  &__error-icon {
    margin: 0 10px;
  }
}

.submission-input-action {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.fleet-tree-height-limiter-wrapper {
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.read-only-wrapper {
  position: relative;
  opacity: 0.5;
}

.read-only-wrapper::before {
  content: '';
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sso-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  &__item {
    margin-bottom: 20px;
    border-color: #eaecef;
    &--full-width {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

.sso-login-list {
  padding: 20px;
  width: 100%;
}

.tabs {
  width: 100%;
}

.tab-list {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: flex;
  justify-content: center;
  flex: 1;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  &--active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
  }
}

.location-history {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__view {
    width: 100%;
    min-width: 150px;
    min-height: 150px;
    max-height: 50%;
    flex: 1;
    margin-bottom: 10px;
  }
  &__header-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
  }
}

.enrollment-staging {
  width: 75%;
  &__qr-code-container {
    margin: 30px;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
  &__generate-button-container {
    margin: 15px 0;
  }
}

.status-display {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  margin: 0 10px;
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  &--positive {
    background-color: #51d451;
    color: white;
  }
}

.async-renderer-with-data-fetching {
  &__wrapper {
    width: 100%;
  }
}

.users-change-group-wrapper {
  max-height: 650px;
  overflow-y: scroll;
  width: 100%;
}

.language-switch {
  color: black;
  margin: 0 10px;
  &__language-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px 0;
  }
  &__language {
    margin-left: 10px;
  }
}

.slider-wrapper {
  margin: 15px 0;
}

.drop-down-select {
  &__label-container {
    display: flex;
    align-items: center;
  }
  &__label {
    margin-left: 5px;
  }
}
