.drop-down-menu {
  position: relative;
  margin-left: 32px;
  width: 160px;
  &__trigger {
    width: 160px !important;
    height: 32px;
  }
  &__dropdown-list {
    position: fixed;
    width: 160px;
    //height: 90px;
    height: auto;
    z-index: 999;
    //margin-left: -60px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 3px 1px 6px;
  }
  &__list-item {
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 5px 0;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &--aligned-left {
      justify-content: flex-start;
      text-align: left;
    }
  }
  &__list-item:hover {
    background: #c4ced691;
  }
  &__list-item-text {
    width: 160px;
    margin-right: 5px;
    white-space: normal;
  }
  &__list-item-icon {
    margin: 0 10px;
  }
}
