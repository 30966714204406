.entity-creation-wizard-steps-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &__primary {
    display: flex;
    justify-content: space-between;
    min-width: 200px;
    width: 30%;
  }
}
