.read-only-list-item {
  width: 100%;
  margin-bottom: 10px;
  &__value-list {
    list-style-type: none;
    padding-left: 0;
  }
  &__value-item {
    font-weight: bolder;
  }
}
