@import 'variables';

.auth_err {
  color: #ff0000;
}

.creation-wizard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__input-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin-bottom: 32px;
  }

  &__label {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.19;
    letter-spacing: 2.1px;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  &__input {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid $dark-blue;
    border-radius: 0;
    outline: none;
    width: 100%;
    max-width: 348px;
    min-width: 150px;
    font-size: 14px;
    line-height: normal;
    text-align: left;
    padding: 6.5px 10.5px;
    background: transparent;

    &:focus {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid $dark-blue;
      border-radius: 0;
      outline: none;
      background: transparent;
    }

    &::placeholder {
      color: $dark-blue;
      opacity: 0.43;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: 0.6px;
      text-overflow: ellipsis;
    }
  }

  &__button-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    background-color: $dark-blue;
    color: $color-white;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.6px;
    border-radius: 2px;
    cursor: pointer;
    text-transform: uppercase;
    &:hover {
      color: $color-white;
    }
  }

  &__back-button-section {
    display: flex;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
    color: $color-black;
  }
}

.creation-wizard-back-button {
  &__link {
    display: flex;
    flex-direction: column;
    color: #000;
  }

  &__icon {
    width: 28px;
  }

  &__title {
    padding-top: 8px;
  }
}

.creation-wizard-wizard {
  display: flex;
  height: 100%;
  width: 100%;

  &__content {
    display: flex;
    height: 100%;
    width: 66.67%;
    overflow-y: scroll;
    flex-direction: column;
    padding: 10%;
  }
  &__inner-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }
  &__close-button {
    color: $color-white;
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
    margin-right: auto;
    display: flex;
  }
  &__close-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    min-height: 24px;
    min-width: 24px;
  }
  &__control-button {
    margin-left: 3px;
    margin-right: 3px;
    text-transform: uppercase;
    &--first {
      margin-left: auto;
    }
  }
}

.creation-wizard-wizard-bar {
  &--incube {
    background: linear-gradient($incube-dark-blue, $color-black);
  }
  &--janam {
    background: linear-gradient($janam-primary-color, #fc575e 75%);
  }
  border-right: 2px solid $lighter-gray;
  height: 100%;
  background: linear-gradient($dark-blue, $color-black);
  width: 33.33%;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  &__title {
    margin-top: 40px;
    font-size: 21px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: 2.1px;
    text-align: center;
    color: $color-white;
    text-transform: uppercase;
  }
  &__icon {
    margin-top: 36px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  &__stepper-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.creation-wizard-wizard-stepper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  &__step {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $color-white;
    border-radius: 50%;
    opacity: 0.16;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: $color-black;
    &--active {
      opacity: 1;
    }
  }
}

.creation-wizard-step-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  &--centered {
    justify-content: center;
  }
}

.creation-wizard-step-inner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.creation-wizard-review-step {
  &__review-item {
    display: flex;
    flex-direction: column;
    &--with-top-margin {
      margin-top: 60px;
    }
  }

  &__review-item-title-area {
    display: flex;
    align-items: baseline;
    flex-direction: row;
    justify-content: space-between;
    width: 320px;
  }

  &__review-item-title {
    font-weight: normal;
    font-size: 21px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #003562;
  }

  &__review-item-edit-nav {
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #003562;
    text-decoration: underline;
  }

  &__review-item-value {
    margin-top: 16px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #003562;
  }
}
